"use client";
import { useState, useEffect, useRef } from "react";
import { PlusSmallIcon } from "@heroicons/react/20/solid";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../Components/Loading";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { Radio, RadioGroup } from "@headlessui/react";
import {
  CheckCircleIcon,
  PrinterIcon,
  MapPinIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../Components/PaymentForm";

const stripePromise = loadStripe("pk_live_2A5OIadpFOD6WtdZF5FVVKqY00mLbkVFM9");
//const stripePromise = loadStripe("pk_test_1jly266CPr5zNJPdPLaAAKdE00UtQzQcTE");

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

const machines = [
  {
    brandName: "Tajima",
    type: "Embroidery Machine",
  },
  {
    brandName: "Barudan",
    type: "Embroidery Machine",
  },
  {
    brandName: "ZSK",
    type: "Embroidery Machine",
  },
  {
    brandName: "Fortever",
    type: "Embroidery Machine",
  },
  {
    brandName: "BAI",
    type: "Embroidery Machine",
  },
  {
    brandName: "Chinese Generic",
    type: "Embroidery Machine",
  },
  {
    brandName: "Happy",
    type: "Embroidery Machine",
  },
  {
    brandName: "All Brands",
    type: "DTF",
  },
  {
    brandName: "All Brands",
    type: "Heat Press",
  },
];
const libraries = ["places"];

export default function Dashboard() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [requestForm, setRequestForm] = useState({
    name: userDetails?.name,
    email: userDetails?.email,
    contact: userDetails?.contact,
    address: userDetails?.address,
    additionalDetails: "",
    machines: [],
  });
  const [requestNumber, setRequestNumber] = useState(
    Math.floor(100000 + Math.random() * 900000)
  );
  const [address, setAddress] = useState("");
  const searchBoxPickUpRef = useRef(null);
  const handlePickUpPlaceChanged = () => {
    const place = searchBoxPickUpRef.current.getPlaces()[0];
    if (place) {
      setAddress(place.formatted_address || place.name);
    }
  };
  const [dashboardData, setDashboardData] = useState({});
  useEffect(() => {
    setIsVisible(true);
    var userData = localStorage.getItem("__BOTTechNetworkUser__");
    if (userData) {
      var userData1 = JSON.parse(userData);
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      setUserDetails({
        ...userData1,
        password: CryptoJS.AES.decrypt(userData1.password, secretKey).toString(
          CryptoJS.enc.Utf8
        ),
      });

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/botTechNetwork/dashboardData`,
          {},
          {
            headers: {
              authorization: userData1?.authToken, // Replace with your token
            },
          }
        )
        .then((response) => {
          if (response?.data?.allRequests) {
            setIsVisible(false);
            setDashboardData(response?.data);
          } else {
            setIsVisible(false);
          }
        })
        .catch((error) => {
          setIsVisible(false);
        });
    } else {
      navigate("/");
    }
  }, []);
  const [tech, setTech] = useState({});
  const searchTech = () => {
    setIsVisible(true);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/botTechNetwork/searchTech`,
        requestForm,
        {
          headers: {
            authorization: userDetails?.authToken, // Replace with your token
          },
        }
      )
      .then((response) => {
        if (response?.data?.success) {
          setIsVisible(false);
          setTech(response?.data?.techData);
          setPaymentModal(true);
        } else {
          setIsVisible(false);
          toast.warn(response?.data?.message);
        }
      })
      .catch((error) => {
        setIsVisible(false);
      });
  };
  const [openMap, setOpenMap] = useState(false);
  const [thisReq, setThisReq] = useState({});
  const updateStatus = (data) => {
    setIsVisible(true);
    var dataToSend = {
      requestNumber: data?.requestNumber,
      status: data?.status,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/botTechNetwork/admin/updateStatus`,
        dataToSend,
        {
          headers: {
            authorization: userDetails?.authToken, // Replace with your token
          },
        }
      )
      .then((response) => {
        if (response?.data?.success) {
          setIsVisible(false);
          toast.success("Success!! Status updated.");
        } else {
          setIsVisible(false);
          toast.warn("Error!! Please try again later.");
        }
      })
      .catch((error) => {
        setIsVisible(false);
        toast.success("Error!! Please try again later.");
      });
  };
  return (
    <>
      <Header />
      {isVisible && <Loading />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div>
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold text-gray-900"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>Create new request</p>
                    <XMarkIcon
                      aria-hidden="true"
                      className="size-6"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </DialogTitle>
                  <div className="mt-2">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm/6 font-medium text-gray-900"
                      >
                        <span className="text-red-700">*</span>Name
                      </label>
                      <div className="mt-2">
                        <input
                          id="name"
                          name="name"
                          type="text"
                          placeholder="John Doe"
                          value={userDetails?.name}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              name: e?.target?.value,
                            });
                          }}
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                        />
                      </div>
                      <label
                        htmlFor="address"
                        className="mt-4 block text-sm/6 font-medium text-gray-900"
                      >
                        <span className="text-red-700">*</span>Address
                      </label>
                      <div className="mt-2">
                        <StandaloneSearchBox
                          onLoad={(ref) => (searchBoxPickUpRef.current = ref)}
                          onPlacesChanged={handlePickUpPlaceChanged}
                        >
                          <input
                            type="text"
                            placeholder="Company full address"
                            value={userDetails?.address}
                            onChange={(e) => {
                              setUserDetails({
                                ...userDetails,
                                address: e.target.value,
                              });
                            }}
                            onBlur={(e) => {
                              setUserDetails({
                                ...userDetails,
                                address: e.target.value,
                              });
                            }}
                            className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                          />
                        </StandaloneSearchBox>
                      </div>
                    </div>
                    <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="email"
                          className="block text-sm/6 font-medium text-gray-900"
                        >
                          <span className="text-red-700">*</span>Email
                        </label>
                        <div className="mt-2">
                          <input
                            id="email"
                            name="email"
                            type="text"
                            value={userDetails?.email}
                            disabled
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="phone"
                          className="block text-sm/6 font-medium text-gray-900"
                        >
                          <span className="text-red-700">*</span>Phone
                        </label>
                        <div className="mt-2">
                          <input
                            id="phone"
                            name="phone"
                            type="text"
                            value={userDetails?.contact}
                            onChange={(e) => {
                              setUserDetails({
                                ...userDetails,
                                contact: e?.target?.value,
                              });
                            }}
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                          />
                        </div>
                      </div>
                    </div>
                    <label
                      htmlFor="machines"
                      className="mt-4 block text-sm/6 font-medium text-gray-900"
                    >
                      <span className="text-red-700">*</span>Please select from
                      the following machines you need help with:
                    </label>
                    <fieldset>
                      <div className="mt-4 space-y-5">
                        {machines?.map((machine, i) => {
                          return (
                            <div className="relative flex items-start" key={i}>
                              <div className="flex h-6 items-center">
                                <input
                                  id={`${machine?.brandName}_${machine?.type}`}
                                  name={`${machine?.brandName}_${machine?.type}`}
                                  type="checkbox"
                                  aria-describedby="comments-description"
                                  className="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  onChange={(e) => {
                                    if (e?.target?.checked) {
                                      setRequestForm({
                                        ...requestForm,
                                        machines: [
                                          ...requestForm?.machines,
                                          machine,
                                        ],
                                      });
                                    } else {
                                      const index =
                                        requestForm?.machines?.findIndex(
                                          (item) =>
                                            item.brandName ===
                                              machine?.brandName &&
                                            item.type === machine?.type
                                        );
                                      requestForm?.machines.splice(index, 1);
                                      setRequestForm({
                                        ...requestForm,
                                        machines: requestForm?.machines,
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <div className="ml-3 text-sm/6">
                                <label
                                  htmlFor={`${machine?.brandName}_${machine?.type}`}
                                  className="font-medium text-gray-900"
                                >
                                  {machine?.brandName}
                                </label>
                                <p
                                  id={`${machine?.brandName}_${machine?.type}`}
                                  className="text-gray-500"
                                >
                                  {machine?.type}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </fieldset>
                    <div className="mt-4 col-span-full">
                      <label
                        htmlFor="about"
                        className="block text-sm/6 font-medium text-gray-900"
                      >
                        Additional details
                      </label>
                      <div className="mt-2">
                        <textarea
                          id="additionalDetails"
                          name="additionalDetails"
                          placeholder="Additional details"
                          rows={3}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                          value={requestForm?.additionalDetails}
                          onChange={(e) => {
                            setRequestForm({
                              ...requestForm,
                              additionalDetails: e?.target?.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    {tech?.name ? (
                      <RadioGroup className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-1 sm:gap-x-4">
                        <Radio
                          aria-label="Tech"
                          className="group relative flex cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus:outline-none data-[checked]:border-transparent data-[focus]:ring-2 data-[focus]:ring-indigo-500"
                        >
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <span className="block text-sm font-medium text-gray-900">
                                {tech?.name}
                              </span>
                              <span className="mt-1 flex items-center text-sm text-gray-500">
                                {tech?.companyName}
                              </span>
                            </span>
                          </span>
                          <CheckCircleIcon
                            aria-hidden="true"
                            className="size-5 text-indigo-600"
                          />
                          <span
                            aria-hidden="true"
                            className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-indigo-500"
                          />
                        </Radio>
                      </RadioGroup>
                    ) : null}
                  </div>
                </div>
              </div>

              {userDetails?.name &&
              userDetails?.email &&
              userDetails?.contact &&
              userDetails?.address &&
              requestForm?.machines?.length > 0 ? (
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                      requestForm.name = userDetails?.name;
                      requestForm.email = userDetails?.email;
                      requestForm.contact = userDetails?.contact;
                      requestForm.address = userDetails?.address;
                      searchTech();
                    }}
                  >
                    Search Technician
                  </button>
                </div>
              ) : null}
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={paymentModal}
        onClose={setPaymentModal}
        className="relative z-10"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div className="">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold text-gray-900"
                  >
                    Confirm Request: {requestNumber}
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Please complete the payment process to book the
                      Technician.
                    </p>
                    <RadioGroup className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-1 sm:gap-x-4">
                      <Radio
                        aria-label="Tech"
                        className="group relative flex cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus:outline-none data-[checked]:border-transparent data-[focus]:ring-2 data-[focus]:ring-indigo-500"
                      >
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <span className="block text-sm font-medium text-gray-900">
                              {tech?.name}
                            </span>
                            <span className="mt-1 flex items-center text-sm text-gray-500">
                              {tech?.companyName}
                            </span>
                          </span>
                        </span>
                        <CheckCircleIcon
                          aria-hidden="true"
                          className="size-5 text-indigo-600"
                        />
                        <span
                          aria-hidden="true"
                          className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-indigo-500"
                        />
                      </Radio>
                    </RadioGroup>

                    <Elements stripe={stripePromise} className="mt-5">
                      <PaymentForm
                        amount={Number(
                          tech?.chargeAmount + tech?.chargeAmount * 0.13
                        )}
                        email={requestForm.email}
                        name={requestForm.name}
                        shippingAddress={requestForm?.address}
                        billingAddress={requestForm?.address}
                        metadata={{
                          requestNumber,
                          requestForm,
                          tech,
                          orderedItems: requestForm?.machines,
                          billingAddress: requestForm?.address,
                          shippingAddress: requestForm?.address,
                          paymentDetails: {
                            subTotal: Number(tech?.chargeAmount),
                            taxEstimate: Number(tech?.chargeAmount * 0.13),
                            orderTotal: Number(
                              tech?.chargeAmount + tech?.chargeAmount * 0.13
                            ),
                          },
                        }}
                        requestNumber={requestNumber}
                        setOpen={setOpen}
                        setPaymentModal={setPaymentModal}
                        toast={toast}
                      />
                    </Elements>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      <Dialog open={openMap} onClose={setOpenMap} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-indigo-100">
                  <MapPinIcon
                    aria-hidden="true"
                    className="size-6 text-white-600"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold text-gray-900"
                  >
                    Service Address
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {thisReq?.requestForm?.name}
                    </p>
                    <p className="text-sm text-gray-500">
                      {thisReq?.requestForm?.address}
                    </p>
                    <p className="text-sm text-gray-500">
                      {thisReq?.requestForm?.email} |{" "}
                      {thisReq?.requestForm?.contact}
                    </p>
                  </div>
                  <div
                    className="mt-2"
                    style={{
                      display: "block",
                      overflow: "hidden",
                      height: "30vh",
                    }}
                  >
                    <iframe
                      src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyDCgA8dHKZISWInKu5FXRv5SYDq73qG9jI&origin=${thisReq?.tech?.address}&destination=${thisReq?.requestForm?.address}&mode=driving`}
                      style={{
                        border: 0,
                        width: "100%",
                        height: "100%",
                      }}
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => {
                    window.open(
                      `https://www.google.com/maps/embed/v1/directions?key=AIzaSyDCgA8dHKZISWInKu5FXRv5SYDq73qG9jI&origin=${thisReq?.tech?.address}&destination=${thisReq?.requestForm?.address}&mode=driving`,
                      "_blank"
                    );
                  }}
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Open in Google Maps
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      <main className="bg-white-100">
        <div className="relative isolate overflow-hidden pt-16">
          {/* Secondary navigation */}
          <header className="pb-4 pt-6 sm:pb-6">
            <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
              <h1 className="text-base/7 font-semibold text-gray-900">
                Dashboard
              </h1>
              {userDetails?.role === "OPERATOR" ? (
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                  className="ml-auto flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <PlusSmallIcon
                    aria-hidden="true"
                    className="-ml-1.5 size-5"
                  />
                  New Request
                </div>
              ) : null}
            </div>
          </header>

          {/* Stats */}
          <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
              <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8">
                <dt className="text-sm/6 font-medium text-gray-500">
                  All Requests
                </dt>

                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                  {dashboardData?.allRequests?.totalCount || "--"}
                </dd>
              </div>
              <div className="sm:border-l lg:border-l flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8">
                <dt className="text-sm/6 font-medium text-gray-500">
                  Confirmed Requests
                </dt>

                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                  {dashboardData?.confirmedRequests?.totalCount || "--"}
                </dd>
              </div>
              <div className="sm:border-l lg:border-l flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8">
                <dt className="text-sm/6 font-medium text-gray-500">
                  Completed Requests
                </dt>

                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                  {dashboardData?.completedRequests?.totalCount || "--"}
                </dd>
              </div>
              <div className="sm:border-l lg:border-l flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8">
                <dt className="text-sm/6 font-medium text-gray-500">
                  Cancelled Requests
                </dt>

                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                  {dashboardData?.cancelledRequests?.totalCount || "--"}
                </dd>
              </div>
            </dl>
          </div>

          <div
            aria-hidden="true"
            className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
          >
            <div
              style={{
                clipPath:
                  "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
              }}
              className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
            />
          </div>
        </div>

        <div className="mt-6 overflow-hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-7xl lg:mx-0 lg:max-w-none">
              <div
                style={{
                  height: "70vh",
                }}
              >
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold text-gray-900">
                      Raised Requests
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                      A list of all the requests raised.
                    </p>
                  </div>
                </div>
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Request Id
                              </th>
                              {userDetails?.role === "OPERATOR" ? (
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Tech. details
                                </th>
                              ) : (
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  User details
                                </th>
                              )}
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Machines
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Status
                              </th>
                              {userDetails?.role === "OPERATOR" ? (
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Amount paid
                                </th>
                              ) : (
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Address
                                </th>
                              )}
                              {/* <th
                                scope="col"
                                className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                              >
                                <span className="sr-only">View</span>
                              </th> */}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {dashboardData?.allRequests?.data?.map((person) => (
                              <tr key={person?.requestNumber}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {person?.requestNumber}
                                </td>
                                {userDetails?.role === "OPERATOR" ? (
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {person?.tech?.name}
                                    <br></br>
                                    {person?.tech?.email}
                                    <br></br>
                                    {person?.tech?.contact}
                                  </td>
                                ) : (
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {person?.requestForm?.name}
                                    <br></br>
                                    {person?.requestForm?.email}
                                    <br></br>
                                    {person?.requestForm?.contact}
                                  </td>
                                )}
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <ul role="list" className="-mb-8">
                                    {person?.requestForm?.machines?.map(
                                      (event, i) => (
                                        <li key={i}>
                                          <div className="relative pb-8">
                                            {i !==
                                            person?.requestForm?.machines
                                              .length -
                                              1 ? (
                                              <span
                                                aria-hidden="true"
                                                className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                                              />
                                            ) : null}
                                            <div className="relative flex space-x-3">
                                              <div>
                                                <span className="flex size-8 items-center bg-blue-500 justify-center rounded-full ring-8 ring-white">
                                                  <PrinterIcon
                                                    aria-hidden="true"
                                                    className="size-5 text-white"
                                                  />
                                                </span>
                                              </div>
                                              <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                                <div>
                                                  <p className="text-sm text-gray-500">
                                                    {event?.brandName}{" "}
                                                    <a className="font-medium text-gray-900">
                                                      {event?.type}
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                  {person?.requestForm?.additionalDetails ? (
                                    <>
                                      <p className="mt-2 font-bold">
                                        Additional details:
                                      </p>
                                      <p
                                        style={{
                                          maxWidth: "20vw",
                                          wordWrap: "break-word",
                                          overflowWrap: "break-word",
                                          whiteSpace: "normal",
                                          lineBreak: "anywhere",
                                          display: "block",
                                        }}
                                      >
                                        {person?.requestForm?.additionalDetails}
                                      </p>
                                    </>
                                  ) : null}
                                </td>
                                {userDetails?.role === "OPERATOR" ? (
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-bold">
                                    {person?.status}
                                  </td>
                                ) : (
                                  <td>
                                    <label
                                      htmlFor="status"
                                      className="block text-sm/6 font-medium text-gray-900"
                                    >
                                      Update Status
                                    </label>
                                    <select
                                      id="status"
                                      name="status"
                                      value={person?.status}
                                      onChange={(e) => {
                                        var updatedItems =
                                          dashboardData?.allRequests?.data?.map(
                                            (item) => {
                                              if (
                                                Number(item.requestNumber) ===
                                                Number(person?.requestNumber)
                                              ) {
                                                return {
                                                  ...item,
                                                  status: e?.target?.value,
                                                }; // Update the name
                                              }
                                              return item;
                                            }
                                          );
                                        setDashboardData({
                                          ...dashboardData,
                                          allRequests: {
                                            ...dashboardData?.allRequests,
                                            data: updatedItems,
                                          },
                                        });
                                        var updateUserData =
                                          updatedItems?.filter(
                                            (item) =>
                                              item?.requestNumber ===
                                              person?.requestNumber
                                          );
                                        if (e?.target?.value !== "") {
                                          updateStatus(updateUserData[0]);
                                        }
                                      }}
                                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
                                    >
                                      <option value="CONFIRMED">
                                        Confirmed
                                      </option>
                                      <option value="IN-PROGRESS">
                                        In-progress
                                      </option>
                                      <option value="COMPLETED">
                                        Completed
                                      </option>
                                      <option value="CANCELLED">
                                        Cancelled
                                      </option>
                                    </select>
                                  </td>
                                )}
                                {userDetails?.role === "OPERATOR" ? (
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    CAD $
                                    {Number(
                                      person?.paymentIntent?.amount / 100
                                    ).toFixed(2)}
                                  </td>
                                ) : (
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-bold">
                                    <div
                                      className="relative flex space-x-2"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setThisReq(person);
                                        setOpenMap(true);
                                      }}
                                    >
                                      <div>
                                        <span className="flex size-8 items-center bg-blue-500 justify-center rounded-full ring-8 ring-white">
                                          <MapPinIcon
                                            aria-hidden="true"
                                            className="size-5 text-white"
                                          />
                                        </span>
                                      </div>
                                      <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                        <div>
                                          <p className="text-sm text-gray-500">
                                            Directions
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                )}
                                {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <a
                                    href="#"
                                    className="text-indigo-600 hover:text-indigo-900"
                                  >
                                    View
                                  </a>
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
