import CryptoJS from "crypto-js";
import React, { useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { Radio, RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";

const deliveryMethods = [
  {
    id: 1,
    title: "Operator",
    turnaround: "Need help with my machine.",
  },
  {
    id: 2,
    title: "Technician",
    turnaround: "Able to fix machines.",
  },
];

const machines = [
  {
    brandName: "Tajima",
    type: "Embroidery Machine",
  },
  {
    brandName: "Barudan",
    type: "Embroidery Machine",
  },
  {
    brandName: "ZSK",
    type: "Embroidery Machine",
  },
  {
    brandName: "Fortever",
    type: "Embroidery Machine",
  },
  {
    brandName: "BAI",
    type: "Embroidery Machine",
  },
  {
    brandName: "Chinese Generic",
    type: "Embroidery Machine",
  },
  {
    brandName: "Happy",
    type: "Embroidery Machine",
  },
  {
    brandName: "All Brands",
    type: "DTF",
  },
  {
    brandName: "All Brands",
    type: "Heat Press",
  },
];

const libraries = ["places"];

export default function Register() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(
    deliveryMethods[0]
  );
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    contact: "",
    companyName: "",
    website: "",
    address: "",
    about: "",
    experience: "",
    role: selectedDeliveryMethod?.title?.toUpperCase(),
    machines: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [address, setAddress] = useState("");
  const searchBoxPickUpRef = useRef(null);
  const handlePickUpPlaceChanged = () => {
    const place = searchBoxPickUpRef.current.getPlaces()[0];
    if (place) {
      setAddress(place.formatted_address || place.name);
    }
  };

  const register = (e) => {
    setIsVisible(true);
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    formData.password = CryptoJS.AES.encrypt(
      formData.password,
      secretKey
    ).toString();
    formData.role = selectedDeliveryMethod?.title?.toUpperCase();
    formData.address = address;

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/botTechNetwork/signup`, formData)
      .then((response) => {
        if (response.data.success) {
          setIsVisible(false);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 12000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setFormData({
            ...formData,
            password: "",
          });
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsVisible(false);
          setFormData({
            ...formData,
            password: "",
          });
        }
      })
      .catch((error) => {
        toast.error("Error, please try again later!!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsVisible(false);
        setFormData({
          ...formData,
          password: "",
        });
      });
  };
  return (
    <>
      {isVisible && <Loading />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            alt="BOT Tech Network"
            src="https://botnetwork.app/static/media/logo.3f6dabdbb8d3c7210251.png"
            className="mx-auto h-40 w-auto"
          />
          <h2 className="mt-2 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
            Register with{" "}
            <a
              href="/"
              style={{
                textDecoration: "underline",
              }}
            >
              BOT Tech Network
            </a>{" "}
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[620px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <div className="space-y-6">
              <div>
                <fieldset>
                  <RadioGroup
                    value={selectedDeliveryMethod}
                    onChange={setSelectedDeliveryMethod}
                    className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4"
                  >
                    {deliveryMethods.map((deliveryMethod) => (
                      <Radio
                        key={deliveryMethod.id}
                        value={deliveryMethod}
                        aria-label={deliveryMethod.title}
                        aria-description={`${deliveryMethod.turnaround} for ${deliveryMethod.price}`}
                        className="group relative flex cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus:outline-none data-[checked]:border-transparent data-[focus]:ring-2 data-[focus]:ring-indigo-500"
                      >
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <span className="block text-sm font-medium text-gray-900">
                              {deliveryMethod.title}
                            </span>
                            <span className="mt-1 flex items-center text-sm text-gray-500">
                              {deliveryMethod.turnaround}
                            </span>
                          </span>
                        </span>
                        <CheckCircleIcon
                          aria-hidden="true"
                          className="size-5 text-indigo-600 [.group:not([data-checked])_&]:hidden"
                        />
                        <span
                          aria-hidden="true"
                          className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-indigo-500"
                        />
                      </Radio>
                    ))}
                  </RadioGroup>
                </fieldset>
              </div>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  <span className="text-red-700">*</span>Name
                </label>
                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="John Doe"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  <span className="text-red-700">*</span>Email
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    placeholder="abc@example.com"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  <span className="text-red-700">*</span>Password
                </label>

                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                  />
                </div>
              </div>
              <>
                <div>
                  <label
                    htmlFor="contact"
                    className="block text-sm/6 font-medium text-gray-900"
                  >
                    <span className="text-red-700">*</span>Phone
                  </label>
                  <div className="mt-2">
                    <input
                      id="contact"
                      placeholder="1234567890"
                      name="contact"
                      type="number"
                      value={formData.contact}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="companyName"
                    className="block text-sm/6 font-medium text-gray-900"
                  >
                    Company Name (Inc. details)
                  </label>
                  <div className="mt-2">
                    <input
                      id="companyName"
                      name="companyName"
                      placeholder="My Company Inc."
                      type="text"
                      value={formData.companyName}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="website"
                    className="block text-sm/6 font-medium text-gray-900"
                  >
                    Website
                  </label>
                  <div className="mt-2">
                    <input
                      id="website"
                      name="website"
                      placeholder="www.mycompany.com"
                      type="text"
                      value={formData.website}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm/6 font-medium text-gray-900">
                    <span className="text-red-700">*</span>Company full address
                  </label>
                  <div className="mt-2">
                    <LoadScript
                      googleMapsApiKey="AIzaSyDCgA8dHKZISWInKu5FXRv5SYDq73qG9jI"
                      libraries={libraries}
                    >
                      <StandaloneSearchBox
                        onLoad={(ref) => (searchBoxPickUpRef.current = ref)}
                        onPlacesChanged={handlePickUpPlaceChanged}
                      >
                        <input
                          type="text"
                          placeholder="Company full address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          onBlur={(e) => {
                            setFormData({
                              ...formData,
                              address: e.target.value,
                            });
                          }}
                          className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                        />
                      </StandaloneSearchBox>
                    </LoadScript>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="about"
                    className="block text-sm/6 font-medium text-gray-900"
                  >
                    About
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="about"
                      placeholder="About yourself"
                      name="about"
                      rows={3}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                      value={formData.about}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {selectedDeliveryMethod?.title?.toUpperCase() ===
                "TECHNICIAN" ? (
                  <>
                    <div>
                      <label
                        htmlFor="experience"
                        className="block text-sm/6 font-medium text-gray-900"
                      >
                        Experience
                      </label>
                      <div className="mt-2">
                        <input
                          id="experience"
                          name="experience"
                          type="text"
                          value={formData.experience}
                          onChange={handleChange}
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                        />
                      </div>
                    </div>
                    <label
                      htmlFor="experience"
                      className="block text-sm/6 font-medium text-gray-900"
                    >
                      <span className="text-red-700">*</span>Please select from
                      the following machines you are good to work with:
                    </label>
                    <fieldset>
                      <legend className="sr-only">Notifications</legend>
                      <div className="space-y-5">
                        {machines?.map((machine, i) => {
                          return (
                            <div className="relative flex items-start" key={i}>
                              <div className="flex h-6 items-center">
                                <input
                                  id={`${machine?.brandName}_${machine?.type}`}
                                  name={`${machine?.brandName}_${machine?.type}`}
                                  type="checkbox"
                                  aria-describedby="comments-description"
                                  className="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  onChange={(e) => {
                                    if (e?.target?.checked) {
                                      setFormData({
                                        ...formData,
                                        machines: [
                                          ...formData?.machines,
                                          machine,
                                        ],
                                      });
                                    } else {
                                      const index =
                                        formData?.machines?.findIndex(
                                          (item) =>
                                            item.brandName ===
                                              machine?.brandName &&
                                            item.type === machine?.type
                                        );
                                      formData?.machines.splice(index, 1);
                                      setFormData({
                                        ...formData,
                                        machines: formData?.machines,
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <div className="ml-3 text-sm/6">
                                <label
                                  htmlFor={`${machine?.brandName}_${machine?.type}`}
                                  className="font-medium text-gray-900"
                                >
                                  {machine?.brandName}
                                </label>
                                <p
                                  id={`${machine?.brandName}_${machine?.type}`}
                                  className="text-gray-500"
                                >
                                  {machine?.type}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </fieldset>
                    <div className="rounded-md bg-yellow-50 p-4 mt-4 text-left">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationTriangleIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-yellow-400"
                          />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-yellow-800">
                            Attention
                          </h3>
                          <div className="mt-2 text-sm text-yellow-700">
                            <p>
                              Our team will review the information and will
                              Approve/Decline your request as be the part of BOT
                              Tech Network.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
              {(formData?.name &&
                formData?.email &&
                formData?.contact &&
                formData?.password &&
                formData?.address &&
                selectedDeliveryMethod?.title?.toUpperCase() === "OPERATOR") ||
              (formData?.name &&
                formData?.email &&
                formData?.contact &&
                formData?.password &&
                formData?.address &&
                formData?.machines?.length > 0 &&
                selectedDeliveryMethod?.title?.toUpperCase() ===
                  "TECHNICIAN") ? (
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={register}
                  >
                    Register
                  </button>
                </div>
              ) : null}
            </div>
          </div>

          <p className="mt-10 text-center text-sm/6 text-gray-500">
            Already a member?{" "}
            <a
              href="/"
              className="font-semibold text-indigo-600 hover:text-indigo-500"
            >
              Sign In
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
