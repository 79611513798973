"use client";
import { useState, useEffect } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Loading from "../Components/Loading";
import { useLoadScript } from "@react-google-maps/api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Header() {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    var userData = localStorage.getItem("__BOTTechNetworkUser__");
    if (userData) {
      var userData1 = JSON.parse(userData);
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      setUserDetails({
        ...userData1,
        password: CryptoJS.AES.decrypt(userData1.password, secretKey).toString(
          CryptoJS.enc.Utf8
        ),
      });
    } else {
      navigate("/");
    }
  }, []);

  const [location, setLocation] = useState({
    adddress: "",
  });
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDCgA8dHKZISWInKu5FXRv5SYDq73qG9jI", // Replace with your API key
    libraries: ["places"], // Load the Places library
  });

  const updateData = () => {
    setIsVisible(true);
    if (userDetails?.password) {
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      userDetails.password = CryptoJS.AES.encrypt(
        userDetails.password,
        secretKey
      ).toString();
    }
    delete userDetails?._id;

    var updateData = userDetails;
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/botTechNetwork/updateUser`,
        updateData,
        {
          headers: {
            authorization: userDetails?.authToken, // Replace with your token
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          toast.success("Information Updated.", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsVisible(false);
          localStorage.setItem(
            "__BOTTechNetworkUser__",
            JSON.stringify(updateData)
          );
          setOpenProfile(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsVisible(false);
        }
      })
      .catch((error) => {
        toast.error("Error, please try again later!!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsVisible(false);
      });
  };

  return (
    <>
      {isVisible && <Loading />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Dialog
        open={openProfile}
        onClose={setOpenProfile}
        className="relative z-10"
        style={{
          zIndex: "9999",
        }}
      >
        <div
          className="fixed inset-0 bg-gray-500"
          style={{
            opacity: "0.5",
          }}
        />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="px-4 py-6 sm:px-6">
                    <div className="flex items-start justify-between">
                      <h2
                        id="slide-over-heading"
                        className="text-base font-semibold text-gray-900"
                      >
                        Profile
                      </h2>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => setOpenProfile(false)}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="size-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Main */}
                  <div>
                    <div className="pb-1 sm:pb-6">
                      <div>
                        <div className="relative h-40 sm:h-56">
                          <img
                            alt=""
                            src={userDetails?.img}
                            className="absolute size-full object-cover"
                          />
                        </div>
                        <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                          <div className="sm:flex-1">
                            <div>
                              <label
                                htmlFor="name"
                                className="block text-sm/6 font-medium text-gray-900"
                              >
                                <span className="text-red-700">*</span>Name
                              </label>
                              <div className="mt-2">
                                <input
                                  id="name"
                                  name="name"
                                  type="text"
                                  value={userDetails?.name}
                                  onChange={(e) => {
                                    setUserDetails({
                                      ...userDetails,
                                      name: e?.target?.value,
                                      img: `https://api.dicebear.com/9.x/initials/svg?seed=${e?.target?.value}`,
                                    });
                                  }}
                                  required
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="email"
                                className="mt-4 block text-sm/6 font-medium text-gray-900"
                              >
                                <span className="text-red-700">*</span>Email
                              </label>
                              <div className="mt-2">
                                <input
                                  id="email"
                                  name="email"
                                  type="text"
                                  value={userDetails?.email}
                                  disabled
                                  required
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="password"
                                className="mt-4 block text-sm/6 font-medium text-gray-900"
                              >
                                <span className="text-red-700">*</span>Password
                              </label>
                              <div className="mt-2">
                                <input
                                  id="password"
                                  name="password"
                                  type="password"
                                  value={userDetails?.password}
                                  onChange={(e) => {
                                    setUserDetails({
                                      ...userDetails,
                                      password: e?.target?.value,
                                    });
                                  }}
                                  required
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="contact"
                                className="mt-4 block text-sm/6 font-medium text-gray-900"
                              >
                                <span className="text-red-700">*</span>Contact
                              </label>
                              <div className="mt-2">
                                <input
                                  id="contact"
                                  name="contact"
                                  type="text"
                                  value={userDetails?.contact}
                                  onChange={(e) => {
                                    setUserDetails({
                                      ...userDetails,
                                      contact: e?.target?.value,
                                    });
                                  }}
                                  required
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="companyName"
                                className="mt-4 block text-sm/6 font-medium text-gray-900"
                              >
                                Company Name (Inc. details)
                              </label>
                              <div className="mt-2">
                                <input
                                  id="companyName"
                                  name="companyName"
                                  type="text"
                                  value={userDetails.companyName}
                                  onChange={(e) => {
                                    setUserDetails({
                                      ...userDetails,
                                      companyName: e?.target?.value,
                                    });
                                  }}
                                  required
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="website"
                                className="mt-4 block text-sm/6 font-medium text-gray-900"
                              >
                                Website
                              </label>
                              <div className="mt-2">
                                <input
                                  id="website"
                                  name="website"
                                  type="text"
                                  value={userDetails.website}
                                  onChange={(e) => {
                                    setUserDetails({
                                      ...userDetails,
                                      website: e?.target?.value,
                                    });
                                  }}
                                  required
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                />
                              </div>
                            </div>
                            <div>
                              <label className="mt-4 block text-sm/6 font-medium text-gray-900">
                                <span className="text-red-700">*</span>Company
                                address
                              </label>
                              <div className="mt-2">
                                <input
                                  id="address"
                                  name="address"
                                  type="text"
                                  value={userDetails.address}
                                  disabled
                                  required
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                />
                              </div>
                            </div>
                            <div>
                              <label className="mt-4 block text-sm/6 font-medium text-gray-900">
                                About
                              </label>
                              <div className="mt-2">
                                <textarea
                                  id="about"
                                  name="about"
                                  rows={3}
                                  className="block w-full max-w-2xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                  value={userDetails.about}
                                  onChange={(e) => {
                                    setUserDetails({
                                      ...userDetails,
                                      about: e?.target?.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            {userDetails?.role === "TECHNICIAN" ? (
                              <div>
                                <label
                                  htmlFor="experience"
                                  className="mt-4 block text-sm/6 font-medium text-gray-900"
                                >
                                  Experience
                                </label>
                                <div className="mt-2">
                                  <input
                                    id="experience"
                                    name="experience"
                                    type="text"
                                    value={userDetails.experience}
                                    onChange={(e) => {
                                      setUserDetails({
                                        ...userDetails,
                                        experience: e?.target?.value,
                                      });
                                    }}
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                  />
                                </div>
                              </div>
                            ) : null}

                            <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                              <button
                                type="button"
                                className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                onClick={() => {
                                  setOpenProfile(false);
                                }}
                              >
                                Cancel
                              </button>
                              {userDetails?.name &&
                              userDetails?.email &&
                              userDetails?.contact &&
                              userDetails?.password &&
                              userDetails?.address ? (
                                <button
                                  type="button"
                                  className="inline-flex w-full shrink-0 items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:flex-1"
                                  onClick={updateData}
                                >
                                  Update
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
      <header
        className="absolute inset-x-0 top-0 flex h-16 border-b border-gray-900/10"
        style={{
          zIndex: 9,
        }}
      >
        <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
          <div className="flex flex-1 items-center gap-x-6">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-3 p-3 md:hidden"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="size-5 text-gray-900" />
            </button>
            <img
              alt="BOT Tech Network"
              src="https://botnetwork.app/static/media/logo.3f6dabdbb8d3c7210251.png"
              className="h-12 w-auto"
            />
          </div>
          <nav className="hidden md:flex md:gap-x-11 md:text-sm/6 md:font-semibold md:text-gray-700">
            <a href="/dashboard">Dashboard</a>
            <a
              onClick={() => {
                setOpenProfile(true);
              }}
              style={{
                cursor: "pointer",
              }}
            >
              Profile
            </a>
            <a
              href="/"
              onClick={() => {
                localStorage.removeItem("__BOTTechNetworkUser__");
                navigate("/");
              }}
            >
              Sign Out
            </a>
          </nav>
          <div className="flex flex-1 items-center justify-end gap-x-8">
            <div
              className="-m-1.5 p-1.5"
              onClick={() => {
                setOpenProfile(true);
              }}
              style={{
                cursor: "pointer",
              }}
            >
              <span className="sr-only">Your profile</span>
              <img
                alt=""
                src={userDetails?.img}
                className="size-8 rounded-full bg-gray-800"
              />
            </div>
          </div>
        </div>
        <Dialog
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
          className="lg:hidden"
        >
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10">
            <div className="-ml-0.5 flex h-16 items-center gap-x-6">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="size-6" />
              </button>
              <div className="-ml-0.5">
                <a href="#" className="-m-1.5 block p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    alt=""
                    src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
                    className="h-8 w-auto"
                  />
                </a>
              </div>
            </div>
            <div className="mt-2 space-y-2">
              <a
                href="/dashboard"
                className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
              >
                Dashboard
              </a>
              <a
                onClick={() => {
                  setOpenProfile(true);
                }}
                className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
              >
                Profile
              </a>
              <a
                href="/"
                className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                onClick={() => {
                  localStorage.removeItem("__BOTTechNetworkUser__");
                  navigate("/");
                }}
              >
                Sign out
              </a>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
    </>
  );
}

export default Header;
